import styled from "styled-components";

export const AnalyticCardContainer = styled.div`

  height: 134px;
  width:255px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1rem;
`;

export const Heading = styled.span`
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  color:#323335;
  width: 200px;
  height: 24px;
  top:5px;
  text-align:left;
  left:10px;
 
`;

export const Value = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33.89px;
  color: #1b1c1e;
  top:10px;
  margin-left:20px;
  position:relative;
`;

export const PercentageChange = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ isIncreased }) => (isIncreased ? "#0CAF60" : "#FA5F1C")};
  margin-top: 0.8rem;
`;
