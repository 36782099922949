import React, { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkToken: () => {},
  user: null,
});

export default function AuthContextProvider({ children }) {
  const [isLoggedIn, setLogin] = useState(false);
  const [user, setUser]= useState(null);
  const location = window.location;

  useEffect(() => {
    checkToken();
  }, []);

  async function login(user) {
    const token = user.token;
    setUser({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email
    })
    setLogin(true);
    if (token && token !== undefined) {
      window.localStorage.setItem("user", JSON.stringify(user))

      if (location.pathname === "/") {
        window.location.replace("/home");
      }
    }
  }

  async function logout() {
    setLogin(false);
    window.localStorage.removeItem("user")
    if(window.location.pathname !== "/") window.location.replace("/");
    setUser(null)
  }

  function checkToken() {
    const userString = window.localStorage.getItem("user");
    if (userString&& userString !== undefined) {
      const user = JSON.parse(userString);
      login(user);
    } else {
      logout();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        checkToken,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
