import React, { useEffect, useState } from "react";
import {
  EpisodesContainer,
  EpisodesHeading,
} from "./styles";
import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { getGrowthDatasByPodcastId, getPodcasts } from "../../services/podcast";
import { Heading } from "../common/AnalyticCard/styles";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function Charts() {
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [loadingPodcasts, setLoadingPodcasts] = useState(false);
  const [loadingGrowthData, setLoadingGrowthData] = useState(false);
  const [podcastData, setPodcastData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [growthData, setGrowthData] = useState(null);
  const navigate = useNavigate();

  const getPodcastsData = async () => {
    setLoadingPodcasts(true);
    try {
      let response = await getPodcasts();
      setPodcastData(response.data);
      console.log(response.data)
    } catch (error) {
      toast.error(error.message.toString());
      setIsError(true);
    }
    setLoadingPodcasts(false);
  };

  const getGrowthData = async () => {
    setLoadingGrowthData(true);
    try {
      const response = await getGrowthDatasByPodcastId(selectedPodcast);
      setGrowthData(response.data);
      console.log(response.data)
    } catch (error) {
      toast.error(error.message.toString());
      setIsError(true);
    }
    setLoadingGrowthData(false);
  }

  useEffect(() => {
    getPodcastsData();
  }, []);

  useEffect(() => {
    if(selectedPodcast !== null && !loadingGrowthData){
      getGrowthData(selectedPodcast);
    }
  }, [selectedPodcast])

  return (
    <EpisodesContainer>
      <EpisodesHeading>Charts</EpisodesHeading>
      {loadingPodcasts ? <Skeleton width={170} height={50} borderRadius={10} /> : 
      <Select
        value={selectedPodcast ? selectedPodcast : ""}
        onChange={(e) => {
          setSelectedPodcast(e.target.value);
        }}
        placeholder={"Select Podcast"}
        inputProps={{ "aria-label": "Without label" }}
        style={{
          width: 170,
          borderRadius: 10,
          background: "#7367F0",
          color: "#ffffff",
          marginTop: "20px",
        }}
        sx={{
          "& .css-hdw1oc": { width: 0 },
        }}>
        {podcastData &&
          podcastData.map((d) => (
            <MenuItem value={d.id} key={d.id}>
              {d.title.length > 20 ? d.title.slice(0, 20) + "..." : d.title}
            </MenuItem>
          ))}
      </Select>}
      {selectedPodcast !== null ? (
        <React.Fragment>
          <TableContainer component={Paper} style={{ borderRadius: "10px", margin: '20px 0' }}>
            {loadingGrowthData ? 
            <Paper>
              <Typography sx={{textAlign: 'center', padding: '50px'}}>Loading...</Typography>
            </Paper>
            :
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>This Month</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Previous Month</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Month on Month Growth</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Total Downloads</TableCell>
                  <TableCell>{growthData.megaphone_downloads.current_month_downloads}</TableCell>
                  <TableCell>{growthData.megaphone_downloads.previous_month_downloads}</TableCell>
                  <TableCell sx={{color: growthData.megaphone_downloads.month_on_month_downloads_growth >= 0 ? 'green' : 'red'}}>{growthData.megaphone_downloads.month_on_month_downloads_growth + "%"}</TableCell>
                </TableRow>
                {/*<TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Current Month</TableCell>
                  <TableCell>2332,44</TableCell>
                  <TableCell>43543,554</TableCell>
                  <TableCell>-13%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Back Catalog</TableCell>
                  <TableCell>2332,44</TableCell>
                  <TableCell>43543,554</TableCell>
                  <TableCell>-13%</TableCell>
                </TableRow>
                  */}
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Episode Released</TableCell>
                  <TableCell>{growthData.megaphone_downloads.current_month_episodes_released}</TableCell>
                  <TableCell>{growthData.megaphone_downloads.previous_month_episodes_released}</TableCell>
                  <TableCell sx={{color: growthData.megaphone_downloads.month_on_month_episodes_growth >= 0 ? 'green' : 'red'}}>{growthData.megaphone_downloads.month_on_month_episodes_growth + '%'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Downloads per episode (Current Month)</TableCell>
                  <TableCell>{growthData.megaphone_downloads.current_month_average_downloads}</TableCell>
                  <TableCell>{growthData.megaphone_downloads.previous_month_average_downloads}</TableCell>
                  <TableCell sx={{color: growthData.megaphone_downloads.month_on_month_average_downloads_growth >= 0 ? 'green' : 'red'}}>{growthData.megaphone_downloads.month_on_month_average_downloads_growth + '%'}</TableCell>
                </TableRow>
                {/*<TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Total Views</TableCell>
                  <TableCell>2332,44</TableCell>
                  <TableCell>43543,554</TableCell>
                  <TableCell>-13%</TableCell>
                </TableRow>
      */}
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Total Views</TableCell>
                  <TableCell>{growthData.youtube_views.current_month_views}</TableCell>
                  <TableCell>{growthData.youtube_views.previous_month_views}</TableCell>
                  <TableCell sx={{color: growthData.youtube_views.month_on_month_views_growth>= 0 ? 'green' : 'red'}}>{growthData.youtube_views.month_on_month_views_growth + '%'}</TableCell>
                </TableRow>
                {/*
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Back Catalog</TableCell>
                  <TableCell>2332,44</TableCell>
                  <TableCell>43543,554</TableCell>
                  <TableCell>-13%</TableCell>
                </TableRow>
                */}
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Episode Released</TableCell>
                  <TableCell>{growthData.youtube_views.current_month_episodes_released}</TableCell>
                  <TableCell>{growthData.youtube_views.previous_month_episodes_released}</TableCell>
                  <TableCell sx={{color: growthData.youtube_views.month_on_month_episodes_growth >= 0 ? 'green' : 'red'}}>{growthData.youtube_views.month_on_month_episodes_growth + '%'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Views per Episode (Current Month)</TableCell>
                  <TableCell>{growthData.youtube_views.current_month_average_views}</TableCell>
                  <TableCell>{growthData.youtube_views.previous_month_average_views}</TableCell>
                  <TableCell sx={{color: growthData.youtube_views.month_on_month_average_views_growth >= 0 ? 'green' : 'red'}}>{growthData.youtube_views.month_on_month_average_views_growth + '%'}</TableCell>
                </TableRow>
                <TableRow sx={{borderTop: '2px solid black', background: '#eee'}}>
                  <TableCell sx={{fontWeight: 'bold'}}>Grand Total</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>{growthData.grand_total.current_month}</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>{growthData.grand_total.previous_month}</TableCell>
                  <TableCell sx={{fontWeight: 'bold', color: growthData.grand_total.month_on_month >= 0 ? 'green' : 'red'}}>{growthData.grand_total.month_on_month + '%'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>}
          </TableContainer>
        </React.Fragment>
      ) : (!loadingPodcasts && 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}>
          <Heading>Please select a podcast</Heading>
        </div>
      )}
    </EpisodesContainer>
  );
}
