import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import SignInComponent from "./components/SignInComponent/SignInComponent";
import DashboardPage from "./components/pages/DashboardPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContextProvider, { useAuth } from "./contexts/authenticationContext";
import ChangePassword from "./components/ChangePasswordComponent";
import ForgotPassword from "./components/ForgotPassword";
import Manage from "./components/Manage";
import UploadCSV from "./components/UploadCSV";
import SideNav from "./components/common/SideNav";
import AddPodcast from "./components/common/AddPodcast";
import Episodes from "./components/pages/Episode";
import DashboardComponent from "./components/common/DashboardComponent";
import ManageEpisodes from "./components/common/ManageEpisodes";
import { Typography } from "@mui/material";
import SingleEpisode from "./components/pages/SingleEpisode";
import Charts from "./components/pages/Chart";
import ViewEpisodes from "./components/pages/ViewEpisodes";
import PodcastDashboardComponent from "./components/common/DashboardComponent/PodcastDashboardComponent";

function App() {
  const {user} = useAuth();
  return (
    <div style={{ display: "flex" }}>
      <Router>
        <AuthContextProvider>
          {user && <SideNav />}
          <Routes>
            <Route path="/" element={<SignInComponent />} />
            {/* <Route
              path={"/home/manage/:platform/:podcastId/episodes"}
              element={<ManageEpisodes />}
            /> */}
            <Route
              path={"/home/manage/:podcastId/episodes"}
              element={<ViewEpisodes />}
            />
            <Route
              path={"/home/manage/:platform/:podcastId"}
              element={<UploadCSV />}
            />
            <Route path={"/home/manage/:podcastId"} element={<Manage />} />
            {/* <Route path="/dashboard" element={<DashboardComponent />} /> */}
            <Route path="/dashboard" element={<PodcastDashboardComponent/>} />

            <Route
              path="/dashboard/:podcastId"
              element={<DashboardComponent />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/home" element={<AddPodcast />} />
            <Route path="/episodes" element={<Episodes />} />
            <Route path={"/episodes/:episodeId"} element={<SingleEpisode />} />
            <Route path={"/charts"} element={<Charts />} />
            <Route
              path="/auth/password_reset/confirm/"
              element={<ChangePassword />}
            />
            <Route
              path="*"
              element={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="button">Not found</Typography>
                </div>
              }
            />
          </Routes>
        </AuthContextProvider>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
