import React, { useEffect, useState } from "react";
import SideNav from "../common/SideNav";
import {
  EpisodesContainer,
  EpisodesHeading,
  EpisodesNav,
  EpisodevsNavItems,
} from "./styles";
import VideoPerformanceData from "../common/VideoPerformanceData";
import { MenuItem, Select, TableContainer } from "@mui/material";
import AudioPerformanceData from "../common/AudioPerformanceData";
import axiosInstance from "../../services/Axios";
import { toast } from "react-toastify";
import BasicTable from "../common/BasicTable";
import { Heading } from "../common/AnalyticCard/styles";
import { useNavigate, useParams } from "react-router-dom";
import AnalyticCard from "../common/AnalyticCard";
import { Button, Heading2, SubHeading, TopSubHeading } from "../common/AddPodcast/styles";
import { Download } from "@mui/icons-material";

export default function SingleEpisode() {

  const {episodeId} = useParams();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [analyticData, setAnalyticData] = useState(
    {
        title: null,
        id: null,
        duration: 0,
        analytics: [
    {
        id: 1,
        title: "Youtube Views",
        label: "views",
        value: 0,
        platform: "youtube",
        // percentageChange: "0.8%",
        isIncreased: true,
      },
      {
        id: 2,
        title: "Youtube Subscribers",
        label: "subscribers",
        value: 0,
        platform: "youtube",
        // percentageChange: "0.8%",
        isIncreased: false,
      },
      {
        id: 3,
        title: "Click-Through Rate",
        label: "click_through_rate",
        value: 0,
        platform: "youtube",
        // percentageChange: "0.8%",
        isIncreased: true,
      },
      {
        id: 4,
        title: "Average % Viewed",
        label: "average_percentage_viewed",
        value: 0,
        platform: "youtube",
        // percentageChange: "0.8%",
        isIncreased: false,
      },
      {
        id: 5,
        title: "Starts",
        label: "starts",
        value: 0,
        platform: "spotify",
        // percentageChange: "0.8%",
        isIncreased: true,
      },
      {
        id: 6,
        title: "Streams",
        label: "streams",
        value: 0,
        platform: "spotify",
        // percentageChange: "0.8%",
        isIncreased: false,
      },
      {
        id: 7,
        title: "First 24 hours",
        label: "first_24_hours",
        value: 0,
        platform: "megaphone",
        // percentageChange: "0.8%",
        isIncreased: true,
      },
      {
        id: 8,
        title: "First 7 days",
        label: "first_7_days",
        value: 0,
        platform: "megaphone",
        // percentageChange: "0.8%",
        isIncreased: false,
      },
      {
        id: 9,
        title: "Unique Listeners",
        label: "unique_listeners",
        value: 0,
        platform: "apple",
        // percentageChange: "0.8%",
        isIncreased: true,
      },
      {
        id: 10,
        title: "Unique Engaged Listeners",
        label: "unique_engaged_listeners",
        value: 0,
        platform: "apple",
        // percentageChange: "0.8%",
        isIncreased: false,
      },
        {
          id: 11,
          title: "Total Plays",
          label: "plays",
          value: 0,
          platform: "apple",
          // percentageChange: "0.8%",
          isIncreased: true,
        },
        /*{
          id: 12,
          title: "Duration",
          label: "duration",
          value: 0,
          platform: "apple",
          // percentageChange: "0.8%",
          isIncreased: false,
        },
        */
        {
          id: 13,
          title: "Average Consumption",
          label: "average_consumption",
          value: 0,
          platform: "apple",
          // percentageChange: "0.8%",
          isIncreased: false,
        },
        {
          id: 14,
          title: 'Downloads',
          label: 'downloads',
          value: 0,
          platform: 'megaphone',
          isIncreased: false,
        }
  ]});
  const [loading, setLoading] = useState(false);

  const getEpisodeData = async (id) => {
    try {
        const response = await axiosInstance.get(`/analytics/platforms/episode/${id}`);
        setAnalyticData({
            id: response.data.apple.id,
            title: response.data.apple.episode_title,
            duration: response.data.apple.duration,
            analytics: analyticData.analytics.map((analytic) => {
                const platform = analytic.platform;
                const key = analytic.label;
                const value = response.data[platform][key] ? response.data[platform][key] : 0;
    
                return {
                    ...analytic,
                    value: value
                }
            })
        })

    } catch (error) {
        toast.error(error.message.toString());
        setIsError(true)
    }
  }

  useEffect(() => {
    console.log(analyticData)
  }, [analyticData])

  useEffect(() => {
    if(episodeId){
        getEpisodeData(episodeId);
    }
  }, [episodeId]);

  return (
    <EpisodesContainer>
      <EpisodesHeading>
        <span>Episodes</span>
        <SubHeading>{analyticData.title}</SubHeading>
      </EpisodesHeading>
      <div style={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', width: '100%', alignItems: 'center'}}>
      <div style={{width: '100%', padding: '20px', background: 'white'}}>
      <div style={{display: 'flex'}}>
      <div style={{padding: '10px', border: '1px solid rgb(240, 240, 240)', margin: '20px', minWidth: '10rem'}}>
        <h4 style={{padding: 0, margin: '0 0 10px 0'}}>Duration</h4>
        <p style={{padding: 0, margin: 0, color: 'grey'}}>{(analyticData.duration / 60).toFixed(0) + ' mins'}</p>
      </div>
      </div>
      <Heading style={{width: '100%', textAlign: 'left', fontWeight: 'bold'}}>Downloads</Heading>
      <div style={{
        display: 'flex'
      }}>
        {
          analyticData.analytics.filter(analytics => (['downloads', 'first_24_hours', 'first_7_days'].includes(analytics.label))).map(val => <AnalyticCard val={val} isPurple />)
        }
        </div>
      </div>
      {!isError && analyticData.analytics
      .filter(analytics => (
        !['click_through_rate', "average_percentage_viewed", "views", 'downloads', 'first_24_hours', 'first_7_days'].includes(analytics.label)
      ))
      .map((val) => {
            return <AnalyticCard isHorizontal={true} val={val} />
        })
      }
      <Heading style={{width: '100%', textAlign: 'left', fontWeight: 'bold', marginTop: '30px'}}>Video Metrics</Heading>
        {analyticData.analytics
        .filter(analytic => (['click_through_rate', "average_percentage_viewed", "views"].includes(analytic.label)))
        .map((val) => {
          return <AnalyticCard isHorizontal={true} val = {val} />
        })
        }
      </div>
      {
        isError && 
        <div>
          <Heading2>Couldn't find analytics for this episode</Heading2>
          <Button onClick={e => navigate('/episodes')}>See podcast analytics</Button>
        </div>
      }
    </EpisodesContainer>
  );
}
