import axiosInstance from "../Axios"

export const addPodcast = async ({
    name,
    hostName,
    description
}) => {
    return axiosInstance.post('/analytics/podcast/', {
        "title": name,
        "host_name": hostName,
        "description": description
    });
};

export const getPodcasts = async () => {
    return axiosInstance.get('/analytics/podcast/');
};

export const getPodcast = async (id) => {
    return axiosInstance.get(`analytics/podcast/${id}`);
};

export const getEpisodesByPodcastId = async (id, sort, order = 'descending', limit = 10, offset = 0) => {
    return axiosInstance.get(`/analytics/episodes/podcast?podcast_id=${encodeURIComponent(id)}&order=${encodeURIComponent(order || "descending")}&sort=${encodeURIComponent(sort || "")}&limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`)
};

export const getGrowthDatasByPodcastId = async (id) => {
    return axiosInstance.get(`/analytics/growthchart/episodes/podcast?podcast_id=${encodeURIComponent(id)}`);
};