import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  BannerImage,
  ErrorMessage,
  FieldHeading,
  FormHeading,
  ImageContainer,
  Input,
  RememberMeContainer,
  RememberMeDiv,
  RememberMeInput,
  RememberMeLabel,
  SignInButton,
  SignInContainer,
  SignInFormContainer,
  SignUpheading,
  ForgotPass,
  SignUpLink,
  LoginUsing,
  GoogleButton,
} from "./SignInComponentStyles";
import { emailValidation } from "../../helpers/validations";
import { SignInImage } from "../../assests";
import { FcGoogle } from "react-icons/fc";
import axiosCall from "../../services/Axios";
import { toast } from "../../helpers/toast";
import { useAuth } from "../../contexts/authenticationContext";
import axiosInstance from "../../services/Axios";
import { useParams, useSearchParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

export default function SignInComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const first_name = searchParams.get('first_name');
  const last_name = searchParams.get('last_name')
  const email = searchParams.get('email');
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post('/auth/google/', {
        token: codeResponse.access_token
        });

        login(response.data);
      } catch (error) {
        console.log(error)
      }
      setLoading(false);
    },
    onError: (error) =>{ 
      setLoading(false);
      console.log(error)
    }
  })

  useEffect(() => {
    if(token && email && first_name){
      login({
        token,
        email,
        first_name,
        last_name,
      });
    }
  }, [])

  const validate = (values) => {
    const errors = {};
    const emailError = emailValidation(values.emailAddress);
    if (emailError) {
      errors.emailAddress = emailError;
    }
    if (!values.password) {
      errors.password = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
      rememberMe: 0,
    },
    validate,
    onSubmit: async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.post("/auth/login/", {
          email: formik.values.emailAddress,
          password: formik.values.password,
        });
        login(response?.data);
        toast.success("Log In Successful");
      } catch (error) {
        console.log(error)
        toast.error(error.response.data.non_field_errors[0]);
      }
      setLoading(false);
    },
  });

  return (
    <SignInContainer>
      <ImageContainer>
        <BannerImage src={SignInImage} />
      </ImageContainer>
      <SignInFormContainer onSubmit={formik.handleSubmit}>
        <FormHeading>Hi, Welcome Back!</FormHeading>
        <div>
          <FieldHeading>Email address</FieldHeading>
          <Input
            id="emailAddress"
            name="emailAddress"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
            placeholder="Enter your email address"
            disabled={loading}
          />
          {formik.touched.emailAddress && formik.errors.emailAddress ? (
            <ErrorMessage>{formik.errors.emailAddress}</ErrorMessage>
          ) : null}
        </div>
        <div>
          <FieldHeading>Password</FieldHeading>
          <Input
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            placeholder="Password"
            type="password"
            disabled={loading}
          />
          {formik.touched.password && formik.errors.password ? (
            <ErrorMessage>{formik.errors.password}</ErrorMessage>
          ) : null}
        </div>
        <RememberMeContainer>
          <RememberMeDiv>
            <RememberMeInput
              type={"checkbox"}
              id="rememberMe"
              name="rememberMe"
              onChange={formik.handleChange}
              value={formik.values.rememberMe}
            />
            <RememberMeLabel htmlFor="rememberMe">Remember Me</RememberMeLabel>
          </RememberMeDiv>
          <ForgotPass to={"/forgot-password"}>Forgot Password?</ForgotPass>
        </RememberMeContainer>
        <SignInButton type="submit">Sign In</SignInButton>
        <LoginUsing>Or Log In Using</LoginUsing>
        <GoogleButton onClick={e => {googleLogin()}}>
          <FcGoogle size={25} /> {loading ? 'Signing in...' : 'Login with google' }
        </GoogleButton>
        <SignUpheading>
          Don’t have an account?
          <SignUpLink to={"/"}>&nbsp;Sign up</SignUpLink>
          </SignUpheading>
      </SignInFormContainer>
    </SignInContainer>
  );
}
