import React, { Fragment, useEffect, useState } from "react";
import {
  AddPodcastContainer,
  WorkSpaceBottomContainer,
  WorkSpaceContainer,
  Button,
  HomeNav,
  HomeNavItems,
} from "./styles";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  LinearProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { addPodcast, getPodcasts } from "../../../services/podcast";
import { toast } from "react-toastify";
import { TableContainer } from "../DashboardComponent/styles";
import Navbar from "../../Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { TbLoader2 } from "react-icons/tb";
import Skeleton from "react-loading-skeleton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddPodcast() {
  const [podcastValue, setPodcastvalue] = React.useState("");
  const [hasMounted, setHasMounted] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hostName, setHostName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [data, setData] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [selected, setSelected] = useState("AddPodcast");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const { platform, podcastId } = useParams();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(false);

  const navigate = useNavigate();

  const handleGetAllPodcasts = async () => {
    setLoading(true);
    try {
      let response = await getPodcasts();

      setData(response.data);
    } catch (error) {
      toast.error(error.message.toString());
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setHasMounted(true);
    handleGetAllPodcasts();
  }, []);

  const handleAddPodcast = async () => {

       console.log(" Hii Podcast button clicked");
       console.log({ podcastValue, hostName, description, loading });
       setLoading(true);
    try {
      await addPodcast({
        name: podcastValue,
        hostName: hostName,
        description: description,
      });
      toast.success("Podcast created successfully!");
      if (openModal) setOpenModal(false);
      await handleGetAllPodcasts();
    } catch (error) {
      toast.error(error.message.toString());
      console.log(error);
    }
    setLoading(false);
    setPodcastvalue("");
    setHostName("");
    setDescription(" ");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setIsMediumScreen(window.innerWidth > 768 && window.innerWidth <= 1024);
      setIsLargeScreen(window.innerWidth > 1024 && window.innerWidth <= 1075);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <WorkSpaceContainer>
        <Navbar />
        <WorkSpaceBottomContainer>
          <HomeNav loading={loading}>
            <div
              style={{
                display: "flex",
                gap: "5rem",
                width: "100%",
              }}
            >
              <>
                {loading ? null : ( // /> //   style={{ margin: "10px" }} //   width={200} //   height={50} // <Skeleton
                  <HomeNavItems
                    onClick={() => setSelected("AddPodcast")}
                    selected={selected === "AddPodcast"}
                    style={{ width: "auto" }}
                  >
                    All Podcast
                  </HomeNavItems>
                )}
                {loading ? null : ( // /> //   style={{ margin: "10px" }} //   width={200} //   height={50} // <Skeleton
                  <HomeNavItems
                    onClick={() => setSelected("UploadData")}
                    selected={selected === "UploadData"}
                  >
                    Upload Data
                  </HomeNavItems>
                )}
                {loading ? null : ( // /> //   style={{ margin: "10px" }} //   width={200} //   height={50} // <Skeleton
                  <HomeNavItems
                    onClick={() => setSelected("GroupEpisodes")}
                    selected={selected === "GroupEpisodes"}
                    style={{ width: "151px", height: "24px", fontSize: "20px" }}
                  >
                    Group Episodes
                  </HomeNavItems>
                )}
                {selected === "AddPodcast" &&
                  (loading ? null : ( // /> //   style={{ margin: "1px" }} //   width={200} //   height={50} // <Skeleton
                    <Button
                      style={{
                        background: "#ffffff",
                        color: "#7367F0",
                        marginLeft: "18rem",
                        marginBottom: "3px",
                        borderRadius: "5px",
                        position: "absolute",
                        width: "175px",
                        top: "188px",
                        left: "75%",
                        transform: "translateX(-50%)",
                        overflow: "hidden",
                        zIndex: 9999,
                        ...(isSmallScreen && {
                          top: "43%",
                          right: "10rem",
                          left: "40%",
                          position: "absolute",
                          display: "flex",
                        }),
                        ...(isMediumScreen && {
                          top: "45%",
                          right: "10rem",
                          left: "48%",
                          position: "absolute",
                          display: "flex",
                        }),
                        ...(isLargeScreen && {
                          top: "45%",
                          right: "10rem",
                          left: "48%",
                          position: "absolute",
                          display: "flex",
                        }),
                      }}
                      // disabled={!podcastValue || !hostName || loading}
                      disabled={loading}
                      onClick={handleOpenModal}
                    >
                      Add New Podcast
                    </Button>
                  ))}
              </>
            </div>
          </HomeNav>
        </WorkSpaceBottomContainer>
        <React.Fragment>
          {selected === "AddPodcast" ? (
            loading ? (
              <Skeleton width={"100]]%"} height={200} />
            ) : (
              <AddPodcastContainer>
                {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      width="300px"
                      height={175}
                      style={{ margin: "20px" }}
                    />
                    <Skeleton
                      width="300px"
                      height={175}
                      style={{ margin: "20px" }}
                    />
                    <Skeleton
                      width="300px"
                      height={175}
                      style={{ margin: "20px" }}
                    />
                    <Skeleton
                      width="300px"
                      height={175}
                      style={{ margin: "20px" }}
                    />
                  </div>
                )}
                {!loading && data && data.length > 0 ? (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          width: "200px",
                          height: "24px",
                          left: "30px",
                          fontFamily: "Inter",
                          color: " #091626",
                          Weight: "500",
                          fontSize: "25px",
                          position: "relative",
                          lineHeight: "24.24px",
                        }}
                      >
                        All Podcast
                      </span>

                      <Fragment>
                        <Modal
                          keepMounted
                          open={openModal}
                          onClose={handleCloseModal}
                          aria-labelledby="keep-mounted-modal-title"
                          aria-describedby="keep-mounted-modal-description"
                        >
                          <Box
                            sx={style}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "cener",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h4"
                              style={{ margin: "2rem 0" }}
                            >
                              Add Podcast
                            </Typography>
                            <TextField
                              disabled={loading}
                              value={podcastValue}
                              onChange={(event) =>
                                setPodcastvalue(event.target.value)
                              }
                              style={{
                                background: "white",
                                width: "100%",
                              }}
                              id="outlined-basic"
                              label="Enter your podcast name"
                              variant="outlined"
                            />
                            <TextField
                              disabled={loading}
                              value={hostName}
                              onChange={(event) =>
                                setHostName(event.target.value)
                              }
                              style={{
                                background: "white",
                                width: "100%",
                                marginTop: "2rem",
                              }}
                              id="outlined-basic"
                              label="Enter Host name"
                              variant="outlined"
                            />
                            <TextField
                              multiline
                              disabled={loading}
                              onChange={(event) =>
                                setDescription(event.target.value)
                              }
                              value={description}
                              style={{
                                background: "white",
                                width: "100%",
                                marginTop: "2rem",
                              }}
                              id="outlined-basic"
                              label="Enter Description"
                              variant="outlined"
                            />
                            <Button
                              style={{
                                background: "#7367F0",
                                color: "white",
                                marginTop: "2rem",
                              }}
                              disabled={!podcastValue || !hostName || loading}
                              onClick={handleAddPodcast}
                            >
                              Add Podcast
                            </Button>
                          </Box>
                        </Modal>
                      </Fragment>
                    </Box>
                    <TableContainer
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {!loading &&
                        data.map((d) => (
                          <Card
                            key={d.id}
                            sx={{
                              margin: 3,
                              width: "355px",
                              height: "160px",
                              borderRadius: "10px",
                            }}
                          >
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {d.title}
                              </Typography>

                              <span
                                style={{
                                  color: "#81888E",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  fontFamily: "Inter",
                                }}
                              >
                                {" "}
                                {d.published_episodes + " "} published episodes{" "}
                              </span>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  style={{
                                    background: "#E4E8EF",
                                    color: "black",
                                    border: "none",
                                    marginTop: "2rem",
                                    borderRadius: "5px",
                                    marginBottom: "2rem",
                                    fontFamily: "Montserrat",
                                    marginRight: "12px",
                                  }}
                                  disabled={loading}
                                  onClick={(e) =>
                                    navigate(`/dashboard/${d.id}`)
                                  }
                                >
                                  <span
                                    style={{
                                      width: "180px",
                                      height: "17px",
                                      gap: "0px",
                                      opacity: "0px",
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      lineHeight: "17.07px",
                                      letterSpacing: "0.4000000059604645px",
                                      textAlign: "center",
                                      color: "#252F40",
                                      display: "inline-flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    Go to Dashboard
                                  </span>
                                </Button>
                                <Button
                                  style={{
                                    background: "#E4E8EF",
                                    color: "#252F40",
                                    border: "none",
                                    marginTop: "2rem",
                                    borderRadius: "5px",
                                    position: "relative",
                                    marginBottom: "4rem",
                                    fontFamily: "Montserrat",
                                  }}
                                  disabled={loading}
                                  onClick={(e) =>
                                    navigate(`/home/manage/${d.id}/episodes`)
                                  }
                                >
                                  <span
                                    style={{
                                      width: "180px",
                                      height: "17px",
                                      gap: "0px",
                                      opacity: "0px",
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      lineHeight: "17.07px",
                                      letterSpacing: "0.4000000059604645px",
                                      textAlign: "center",
                                      color: "#252F40",
                                      display: "inline-flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    View Episodes
                                  </span>
                                </Button>
                              </Box>
                            </CardContent>
                          </Card>
                        ))}
                    </TableContainer>
                  </Box>
                ) : null}
              </AddPodcastContainer>
            )
          ) : selected === "UploadData" ? (
            <Box>
              {/* <Typography variant="h5" style={{ margin: "2rem 0" }}>
                Add Podcast
              </Typography> */}

              <Fragment>
                <Modal
                  keepMounted
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box
                    sx={style}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "cener",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h4" style={{ margin: "2rem 0" }}>
                      Add Podcast
                    </Typography>
                    <TextField
                      disabled={loading}
                      value={podcastValue}
                      onChange={(event) => setPodcastvalue(event.target.value)}
                      style={{
                        background: "white",
                        width: "100%",
                      }}
                      id="outlined-basic"
                      label="Enter your podcast name"
                      variant="outlined"
                    />
                    <TextField
                      disabled={loading}
                      value={hostName}
                      onChange={(event) => setHostName(event.target.value)}
                      style={{
                        background: "white",
                        width: "100%",
                        marginTop: "2rem",
                      }}
                      id="outlined-basic"
                      label="Enter Host name"
                      variant="outlined"
                    />
                    <TextField
                      multiline
                      disabled={loading}
                      onChange={(event) => setDescription(event.target.value)}
                      value={description}
                      style={{
                        background: "white",
                        width: "100%",
                        marginTop: "2rem",
                      }}
                      id="outlined-basic"
                      label="Enter Description"
                      variant="outlined"
                    />
                    <Button
                      style={{
                        background: "#7367F0",
                        color: "white",
                        marginTop: "2rem",
                      }}
                      disabled={!podcastValue || !hostName || loading}
                      onClick={handleAddPodcast}
                    >
                      Add Podcast
                    </Button>
                  </Box>
                </Modal>
              </Fragment>
              {/* <TextField
                disabled={loading}
                value={podcastValue}
                onChange={(event) => setPodcastvalue(event.target.value)}
                style={{
                  background: "white",
                  width: "60%",
                }}
                id="outlined-basic"
                label="Enter your podcast name"
                variant="outlined"
              />
              <TextField
                disabled={loading}
                value={hostName}
                onChange={(event) => setHostName(event.target.value)}
                style={{
                  background: "white",
                  width: "60%",
                  marginTop: "2rem",
                }}
                id="outlined-basic"
                label="Enter Host name"
                variant="outlined"
              />
              <TextField
                multiline
                disabled={loading}
                onChange={(event) => setDescription(event.target.value)}
                value={description}
                style={{
                  background: "white",
                  width: "60%",
                  marginTop: "2rem",
                }}
                id="outlined-basic"
                label="Enter Description"
                variant="outlined"
              />
              <Button
                style={{
                  background: "#7367F0",
                  color: "white",
                  marginTop: "2rem",
                }}
                disabled={!podcastValue || !hostName || loading}
                onClick={handleAddPodcast}
              >
                Add Podcast
              </Button> */}
              <Button
                style={{
                  background: "#ffffff",
                  color: "#7367F0",
                  marginLeft: "18rem",
                  marginBottom: "3px",
                  borderRadius: "5px",
                  position: "absolute",
                  width: "175px",
                  top: "188px",
                  left: "75%",
                  transform: "translateX(-50%)",
                  overflow: "hidden",
                  zIndex: 9999,
                  ...(isSmallScreen && {
                    top: "43%",
                    right: "10rem",
                    left: "40%",
                    position: "absolute",
                    display: "flex",
                  }),
                  ...(isMediumScreen && {
                    top: "45%",
                    right: "10rem",
                    left: "48%",
                    position: "absolute",
                    display: "flex",
                  }),
                  ...(isLargeScreen && {
                    top: "45%",
                    right: "10rem",
                    left: "48%",
                    position: "absolute",
                    display: "flex",
                  }),
                }}
                disabled={loading}
                onClick={handleOpenModal}
              >
                Add New Podcast
              </Button>
            </Box>
          ) : (
            <Skeleton width="100%" height={200} />
          )}
        </React.Fragment>
      </WorkSpaceContainer>
    </>
  );
}
