import { Logout, PersonAdd, Settings } from '@mui/icons-material'
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsBell } from 'react-icons/bs'
import { useAuth } from '../../contexts/authenticationContext'
import { WorkSpaceTopContainer, WorkSpaceTopRightSection } from '../common/AddPodcast/styles'
import { ShadowContainer } from '../common/Container/styles'
import axiosInstance from '../../services/Axios'
import { toast } from 'react-toastify'
import { useMediaQuery } from "@mui/material";


const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, logout } = useAuth();
  const open = Boolean(anchorEl);

  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px)");
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function handleLogOut() {
    try {
      const res = await axiosInstance.post("/auth/logout/", "");
      if (res.status === 200) {
        logout();
        toast.success("Logged Out Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <WorkSpaceTopContainer>
      <span
        style={{
          display: "block",
          fontSize: isMediumScreen ? "14px" : "inherit",
        }}
      >
        {" "}
        {user &&
          (user.firstName !== "" || user.lastName !== "") &&
          `Hi ${user.firstName + " " + user.lastName}`}
      </span>

      <WorkSpaceTopRightSection>
        <ShadowContainer
          margin="0.5rem"
          padding="2rem"
          style={{
            display: "flex",
            width: isSmallScreen ? "100%" : isMediumScreen ? "60%" : "40%",
            maxWidth: "400px",
            height: "30px",
            padding: "0.5rem 1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiSearch size={20} color="#26293080" />
          <input
            placeholder="Search..."
            type={"text"}
            style={{
              width: "90%",
              height: "100%",
              margin: "0px 5px",
              outline: "none",
              border: "none",
              fontSize: "16px",
            }}
          />
        </ShadowContainer>
        <ShadowContainer
          margin="0.5rem"
          padding="0.5rem"
          style={{
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BsBell size={20} />
        </ShadowContainer>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }} alt="Thomas"></Avatar>
          <Typography style={{ marginLeft: "10px" }}>
            {user?.firstName ?? ""}
          </Typography>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </WorkSpaceTopRightSection>
    </WorkSpaceTopContainer>
  );
};

export default Navbar;