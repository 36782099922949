import React, { useEffect, useState } from "react";
import {
  CardsContainer,
  DashboardContainer,
  DropDownContainer,
  Heading,
  PlaceholderItem,
  TableContainer,
  TableTopContainer,
  TableContainerDashboard,
  Dashboardtableheading,
  DashboardtableheadingEpisode,
  DashboardTableCell,
} from "./styles";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import AnalyticCard from "../AnalyticCard";
import { useNavigate, useParams } from "react-router-dom";
import BasicTable from "../BasicTable";
import { toast } from "react-toastify";
import { ShadowContainer } from "../Container/styles";
import { Button } from "../AddPodcast/styles";
import { getEpisodesByPodcastId, getPodcasts } from "../../../services/podcast";

import Skeleton from "react-loading-skeleton";

export default function DashboardComponent() {
  const { podcastId } = useParams();
  const [data, setData] = useState(null);
  const [selectSortVal, setSelectSortVal] = useState("downloads");
  const [selectOrderVal, setSelectOrderVal] = useState("descending");
  const [podcastData, setPodcastData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [page, setPage] = useState(0);
  const [analyticData, setAnalyticData] = useState([
    {
      id: 1,
      title: "Episodes Released",
      label: "total_episodes",
      value: "404k",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Downloads",
      label: "total_downloads",
      value: "404k",
      isIncreased: true,
    },
    {
      id: 3,
      title: "Views",
      label: "total_views",
      value: "300k",
      isIncreased: false,
    },
    {
      id: 4,
      title: "Subscribers",
      label: "total_subscribers",
      value: "300k",
      isIncreased: false,
    },
  ]);
  const navigate = useNavigate();

  const getPodcastsData = async () => {
    try {
      let response = await getPodcasts();
      setPodcastData(response.data);
      console.log(response.data);
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  useEffect(() => {
    getPodcastsData();
  }, []);

  const handleSortSelectChange = (event) => {
    setSelectSortVal(event.target.value);
  };

  const handleOrderSelectChange = (event) => {
    setSelectOrderVal(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  };

  const getEpisodes = async () => {
    setLoading(true);
    try {
      const response = await getEpisodesByPodcastId(
        podcastId,
        selectSortVal,
        selectOrderVal,
        rowsPerPage,
        page * rowsPerPage
      );
      console.log(response);
      if (response.data.msg) {
        setData([]);
      } else {
        setData(response.data);
        setAnalyticData(
          analyticData.map((analytic) => {
            const key = analytic.label;
            const value = response.data[key];
        setAnalyticData(
          analyticData.map((analytic) => {
            const key = analytic.label;
            const value = response.data[key];

            return {
              ...analytic,
              value: value ?? 0,
            };
          })
        );
            return {
              ...analytic,
              value: value ?? 0,
            };
          })
        );
      }
    } catch (error) {
      toast.error(error.message.toString());
    }
    setLoading(false);
  };

  useEffect(() => {
    if (podcastId) getEpisodes();
  }, [selectOrderVal, selectSortVal, page, rowsPerPage]);

  return podcastId ? (
    <DashboardContainer style={{ width: "75%" }}>
      <Heading>Dashboard</Heading>
      {loading ? (
        <>
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0",
              flexWrap: "wrap",
            }}
          >
            <Skeleton width="16.5rem" height={"12.125rem"} />
            <Skeleton width="16.5rem" height={"12.125rem"} />
            <Skeleton width="16.5rem" height={"12.125rem"} />
            <Skeleton width="16.5rem" height={"12.125rem"} />
          </span>
          <Skeleton width={"100%"} height={"200px"} />
        </>
      ) : data && data?.episodes && data?.episodes?.length > 0 ? (
        <React.Fragment>
          <DropDownContainer>
            <Select
              displayEmpty
              value={null}
              inputProps={{ "aria-label": "Without label" }}
              style={{ width: 130, borderRadius: 10, background: "#7367F0" }}
              sx={{ "& .css-hdw1oc": { width: 0 } }}
            >
              <MenuItem disabled value="">
                <PlaceholderItem>This Week</PlaceholderItem>
              </MenuItem>
            </Select>
          </DropDownContainer>
          <CardsContainer style={{ flexWrap: "wrap" }}>
            {analyticData.map((val) => {
              return <AnalyticCard val={val} loading={loading} />;
            })}
          </CardsContainer>
          <TableContainerDashboard>
            <TableTopContainer>
              <div
                style={{
                  display: "flex",
                  gap: "1.5rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width: "311px",
                    height: "22px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "21.78px",
                    color: "#1B1C1E",
                  }}
                >
                  Top performing Episodes
                </Typography>
                <div style={{ display: "flex", gap: "1.5rem" }}>
                  <Select
                    displayEmpty
                    value={selectSortVal}
                    onChange={handleSortSelectChange}
                    inputProps={{ "aria-label": "Without label" }}
                    style={{
                      width: 180,
                      height: 42,
                      borderRadius: 10,
                      background: "#ffffff",
                      color: "#858688",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "18px",
                      outline: "none",
                      transition: "border-bottom-width 0.3s",
                      textAlign: "center",
                    }}
                    sx={{
                      "& .css-hdw1oc": { width: 0 },
                      "&:hover .MuiInputBase-input": {
                        borderColor: "#f5f5f5",
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <PlaceholderItem style={{ color: "#858688" }}>
                        Sort By
                      </PlaceholderItem>
                    </MenuItem>
                    <MenuItem value="applePlays">Apple Plays</MenuItem>
                    <MenuItem value="downloads">Downloads</MenuItem>
                    <MenuItem value="spotifyStreams">Spotify Streams</MenuItem>
                    <MenuItem value="youtubeViews">Youtube Views</MenuItem>
                  </Select>

                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={selectOrderVal}
                    onChange={handleOrderSelectChange}
                    style={{
                      width: 140,
                      height: 42,
                      borderRadius: 10,
                      background: "#ffffff",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "18px",
                      outline: "none",
                      color: "#858688",
                    }}
                    sx={{ "& .css-hdw1oc": { width: 0 } }}
                  >
                    <MenuItem disabled value="">
                      <PlaceholderItem style={{ color: "#858688" }}>
                        Order by
                      </PlaceholderItem>
                    </MenuItem>
                    <MenuItem value="ascending">Ascending</MenuItem>
                    <MenuItem value="descending">Descending</MenuItem>
                  </Select>
                </div>
              </div>
            </TableTopContainer>
            {data.episodes && data.episodes.length > 0 && (
              <>
                <BasicTable
                  totalCount={data.total_episodes}
                  onPageChange={handlePageChange}
                  onRowsChangePerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  TableHeading={[
                    <DashboardtableheadingEpisode>
                      Episodes
                    </DashboardtableheadingEpisode>,
                    <Dashboardtableheading>Release Date</Dashboardtableheading>,
                    <Dashboardtableheading>Downloads</Dashboardtableheading>,
                    <Dashboardtableheading>Apple Plays</Dashboardtableheading>,
                    <Dashboardtableheading>
                      Spotify Streams
                    </Dashboardtableheading>,
                    <Dashboardtableheading>
                      Youtube Views
                    </Dashboardtableheading>,
                  ]}
                  TableData={data.episodes.map((d) => ({
                    episode_title: (
                      <DashboardTableCell style={{textAlign:"left",width:"370px"}}>
                        <a href={`/episodes/${d.id}`}>
                          {d.title.length > 40
                            ? d.title.substr(0, 40) + "..."
                            : d.title}
                        </a>
                      </DashboardTableCell>
                    ),
                    publish_date: (
                      <DashboardTableCell>
                        {d.published_date}
                      </DashboardTableCell>
                    ),
                    downloads: (
                      <DashboardTableCell>{d.downloads}</DashboardTableCell>
                    ),
                    applePlays: (
                      <DashboardTableCell>{d.apple_plays}</DashboardTableCell>
                    ),
                    spotifyStreams: (
                      <DashboardTableCell>
                        {d.spotify_streams}
                      </DashboardTableCell>
                    ),
                    youtubeViews: (
                      <DashboardTableCell>{d.yt_views}</DashboardTableCell>
                    ),
                  }))}
                />
                <Button
                  style={{
                    background: "#7367F0",
                    color: "white",
                    marginTop: "2rem",
                    width: "160px",
                    height: "45px",
                  }}
                  onClick={() => {
                    navigate(`/episodes`);
                  }}
                >
                  See all episodes
                </Button>
              </>
            )}
          </TableContainerDashboard>
        </React.Fragment>
      ) : (
        <Box>
          <ShadowContainer>
            <Typography variant="body1">No episodes found</Typography>
          </ShadowContainer>
        </Box>
      )}
    </DashboardContainer>
  ) : (
    <DashboardContainer>
      <Heading>Dashboard</Heading>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          margin: "5rem",
          background: "white",
        }}
      >
        <Button onClick={() => navigate("/home")}>Select Podcast</Button>
      </Box>
    </DashboardContainer>
  );
}
