import axios from "axios";

const axiosInstance =  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use(
    config => {
      const userString = localStorage.getItem('user');
      let token = null;
      if(userString){
        const user = JSON.parse(userString);
        token = JSON.parse(userString)?.token;
      }
      const auth = token ? `Token ${token}` : null;
      if(auth) config.headers.Authorization = auth;
      return config;
    },
    error => Promise.reject(error),
  );

export default axiosInstance;