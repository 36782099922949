import React, { useEffect, useState } from "react";
import {
  CardsContainer,
  DashboardContainer,
  DropDownContainer,
  Heading,
  PlaceholderItem,
  TableContainer,
  TableTopContainer,
} from "./styles";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import AnalyticCard from "../AnalyticCard";
import { useNavigate, useParams } from "react-router-dom";
import BasicTable from "../BasicTable";
import { toast } from "react-toastify";
import { ShadowContainer } from "../Container/styles";
import { Button } from "../AddPodcast/styles";
import { getEpisodesByPodcastId, getPodcasts } from "../../../services/podcast";
import Skeleton from "react-loading-skeleton";

export default function DashboardComponent() {
  const { podcastId } = useParams();
  const [data, setData] = useState(null);
  const [selectSortVal, setSelectSortVal] = useState("downloads");
  const [selectOrderVal, setSelectOrderVal] = useState("descending");
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [podcasts, setPodcasts] = useState([]);
  const [podcastData, setPodcastData] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState(" ");
  const [page, setPage] = useState(0);
  const [analyticData, setAnalyticData] = useState([
    {
      id: 1,
      title: "Episodes Released",
      label: "total_episodes",
      value: "404k",
      //percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Downloads",
      label: "total_downloads",
      value: "404k",
      //percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 3,
      title: "Views",
      label: "total_views",
      value: "300k",
      //percentageChange: "0.8%",
      isIncreased: false,
    },
    {
      id: 4,
      title: "Subscribers",
      label: "total_subscribers",
      value: "300k",
      //percentageChange: "0.8%",
      isIncreased: false,
    },
  ]);
  const navigate = useNavigate();

  const getPodcastsData = async () => {
    try {
      let response = await getPodcasts();
      setPodcastData(response.data);
      if (!selectedPodcast && response.data.length > 0) {
        setSelectedPodcast(response.data.id);
      }
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  useEffect(() => {
    getPodcastsData();
  }, [selectedPodcast]);

  const handleSortSelectChange = (event) => {
    setSelectSortVal(event.target.value);
  };

  const handleOrderSelectChange = (event) => {
    setSelectOrderVal(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getEpisodes = async () => {
    setLoading(true);
    try {
      const response = await getEpisodesByPodcastId(
        selectedPodcast,
        selectSortVal,
        selectOrderVal,
        rowsPerPage,
        page * rowsPerPage
      );
      if (response.data.msg) {
        setData([]);
      } else {
        setData(response.data);
        setAnalyticData(
          analyticData.map((analytic) => {
            const key = analytic.label;
            const value = response.data[key];

            return {
              ...analytic,
              value: value ?? 0,
            };
          })
        );
      }
    } catch (error) {
      toast.error(error.message.toString());
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedPodcast) {
      getEpisodes();
    }
  }, [selectedPodcast, selectOrderVal, selectSortVal, page, rowsPerPage]);

  const handlePodcastSelectChange = (event) => {
    const selectedPodcastId = event.target.value;
    setSelectedPodcast(selectedPodcastId);
  };

  return (
    <DashboardContainer style={{ width: "75%" }}>
      <Heading>Dashboard</Heading>

      <FormControl fullWidth>
        {!selectedPodcast && (
          <InputLabel
            id="select-podcast-label"
            style={{
              color: "#ffffff",
              marginTop: "20px",
              fontFamily: "Inter",
            }}
          >
            Select Podcast
          </InputLabel>
        )}

        <Select
          value={selectedPodcast ? selectedPodcast : ""}
          onChange={handlePodcastSelectChange}
          placeholder={"Select Podcast"}
          inputProps={{ "aria-label": "Without label" }}
          style={{
            width: 170,
            borderRadius: 10,
            background: "#7367F0",
            color: "#ffffff",
            marginTop: "20px",
            width: "193px",
            height: "50px",
            outline: "none",
            border: "none",
          }}
          sx={{
            "& .css-hdw1oc": { width: 0 },
          }}
        >
          {podcastData &&
            podcastData.map((d) => (
              <MenuItem value={d.id} key={d.id}>
                {d.title.length > 20 ? d.title.slice(0, 20) + "..." : d.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {loading ? (
        <>
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0",
              flexWrap: "wrap",
            }}
          >
            <Skeleton width="14.5rem" height={"16.125rem"} />
            <Skeleton width="14.5rem" height={"16.125rem"} />
            <Skeleton width="14.5rem" height={"16.125rem"} />
            <Skeleton width="14.5rem" height={"16.125rem"} />
          </span>
          <Skeleton width={"100%"} height={"200px"} />
        </>
      ) : data && data?.episodes && data?.episodes?.length > 0 ? (
        <React.Fragment>
          <DropDownContainer
            style={{
              display: "flex",
              position: "absolute",
              left: "36%",
              top: "69px",
            }}
          >
            <Select
              displayEmpty
              value={""}
              inputProps={{ "aria-label": "Without label" }}
              style={{
                width: "120px",
                borderRadius: 10,
                background: "#7367F0",
                height: "50px",
              }}
              sx={{
                "& .css-hdw1oc": { width: 0 },
              }}
            >
              <MenuItem disabled value="">
                <PlaceholderItem
                  style={{
                    fontFamily: "Inter",
                    fontsize: "16px",
                    fontWeight: "500",
                  }}
                >
                  This Week
                </PlaceholderItem>
              </MenuItem>
            </Select>
          </DropDownContainer>

          <CardsContainer
            style={{
              flexWrap: "wrap",
            }}
          >
            {analyticData.map((val) => {
              return <AnalyticCard val={val} loading={loading} />;
            })}
          </CardsContainer>
          <TableContainer>
            <TableTopContainer>
              <div
                style={{
                  display: "flex",
                  gap: "1.5rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h6">Top performing Episodes</Typography>
                <div style={{ display: "flex", gap: "1.5rem" }}>
                  <Select
                    displayEmpty
                    value={selectSortVal}
                    onChange={handleSortSelectChange}
                    inputProps={{ "aria-label": "Without label" }}
                    style={{
                      width: 300,
                      borderRadius: 10,
                      background: "#ffffff",
                    }}
                    sx={{
                      "& .css-hdw1oc": { width: 0 },
                    }}
                  >
                    <MenuItem disabled value="">
                      <PlaceholderItem style={{ color: "#858688" }}>
                        Sort By
                      </PlaceholderItem>
                    </MenuItem>
                    <MenuItem value="applePlays">Apple Plays</MenuItem>
                    <MenuItem value="downloads">Downloads</MenuItem>
                    <MenuItem value="spotifyStreams">Spotify Streams</MenuItem>
                    <MenuItem value="youtubeViews">Youtube Views</MenuItem>
                  </Select>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={selectOrderVal}
                    onChange={handleOrderSelectChange}
                    style={{
                      width: 130,
                      borderRadius: 10,
                      background: "#ffffff",
                    }}
                    sx={{
                      "& .css-hdw1oc": { width: 0 },
                    }}
                  >
                    <MenuItem disabled value="">
                      <PlaceholderItem style={{ color: "#858688" }}>
                        Order by
                      </PlaceholderItem>
                    </MenuItem>
                    <MenuItem value="ascending">Ascending</MenuItem>
                    <MenuItem value="descending">Descending</MenuItem>
                  </Select>
                </div>
              </div>
            </TableTopContainer>
            {data.episodes && data.episodes.length > 0 && (
              <>
                <BasicTable
                  totalCount={data.total_episodes}
                  onPageChange={handlePageChange}
                  onRowsChangePerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  TableHeading={[
                    "Title",
                    "Release Date",
                    "Downloads",
                    "Apple Plays",
                    "Spotify Streams",
                    "Youtube Views",
                  ]}
                  TableData={data.episodes.map((d) => ({
                    episode_title: (
                      <a href={`/episodes/${d.id}`}>
                        {d.title.length > 40
                          ? d.title.substr(0, 40) + "..."
                          : d.title}
                      </a>
                    ),
                    publish_date: d.published_date,
                    downloads: d.downloads,
                    applePlays: d.apple_plays,
                    spotifyStreams: d.spotify_streams,
                    youtubeViews: d.yt_views,
                  }))}
                />
                <Button
                  style={{
                    background: "#7367F0",
                    color: "white",
                    marginTop: "2rem",
                  }}
                  onClick={(event) => {
                    navigate(`/episodes`);
                  }}
                >
                  See all episodes
                </Button>
              </>
            )}
          </TableContainer>
        </React.Fragment>
      ) : selectedPodcast == " " ? (
        <Box>
          <ShadowContainer>
            <Typography
              variant="body"
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "25px",
                top: "40%",
                position: "relative",
                fontFamily: "Inter",
                height: "80vh",
              }}
            >
              Please select a podcast
            </Typography>
          </ShadowContainer>
        </Box>
      ) : (
        <Box>
          <ShadowContainer>
            <Typography
              variant="body1"
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "25px",
                top: "60%",
                position: "relative",
                fontFamily: "Inter",
                height: "80vh",
              }}
            >
              No episodes found
            </Typography>
          </ShadowContainer>
        </Box>
      )}
    </DashboardContainer>
  );
}
