import React from "react";
import {
  AnalyticCardContainer,
  Heading,
  PercentageChange,
  Value,
} from "./styles";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { Popover, Typography } from "@mui/material";

export default function AnalyticCard(props) {
  const {isPurple, isHorizontal} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  return (
    <>
    <AnalyticCardContainer
    onMouseEnter={handlePopoverOpen}
    onMouseLeave={handlePopoverClose}
    style={{
      flexDirection: isHorizontal && 'row',
      height: (isHorizontal || isPurple) && '100%',
      minWidth: isHorizontal && 'fit-content',
      width: isPurple && '14rem',
      padding: '20px',
      minHeight: isPurple && '9rem',
      background: isPurple && '#F7F6FF'
    }}
    >
      <Heading style={{marginRight: isHorizontal && '20px'}}>{props.val.title}</Heading>
      <div>
        <Value style={{fontSize: (isHorizontal || isPurple) && '28px'}}> 
           {Intl.NumberFormat('en-US', {
            notation: "compact",
            maximumFractionDigits: 1,
            roundingMode: 'floor'
          }).format(props.val.value)}
          </Value>
        {props.val.percentageChange && 
        <PercentageChange isIncreased={props.val.isIncreased}>
          {props.val.isIncreased ? <BsArrowUp /> : <BsArrowDown />}
          {props.val.percentageChange}
        </PercentageChange>}
      </div>
    </AnalyticCardContainer>
    <Popover
    id="mouse-over-popover"
    sx={{
      pointerEvents: 'none',
    }}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={handlePopoverClose}
    disableRestoreFocus
  >
    <Typography sx={{ p: 1 }}>{props.val.value}</Typography>
  </Popover>
</>
  );
}