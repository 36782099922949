import React from "react";
import AnalyticCard from "../AnalyticCard";
import { TableContainer, TopContainer } from "./styles";
import BasicTable from "../BasicTable";
import { SubHeading } from "../AddPodcast/styles";

const data = [
  {
    id: 1,
    title: "Average Downloads",
    value: "404k",
    // percentageChange: "0.8%",
    isIncreased: true,
  },
  {
    id: 2,
    title: "Average Plays",
    value: "300k",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
  {
    id: 3,
    title: "Average Consumption %",
    value: "404k",
    // percentageChange: "0.8%",
    isIncreased: true,
  },
  {
    id: 4,
    title: "Avg Downloads in first 24 Hrs",
    value: "300k",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
  {
    id: 5,
    title: "Avg Downloads in first 7 Days",
    value: "300k",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
  {
    id: 6,
    title: "Average Subs",
    value: "300k",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
  {
    id: 7,
    title: "Starts",
    value: "300k",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
  {
    id: 8,
    title: "Streams",
    value: "300k",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
];

const TableHeading = [
  "Pod Ep",
  "YT Ep",
  "Publish Date",
  "Date of Week",
  "Total DL",
  "First 24 Hrs",
  "First 7 Days",
];
const TableData = [
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
  {
    PODepisode: "Victor Pacheco",
    YTepisode: "Yes",
    publishDate: "Lorem",
    DOW: "DD-MM-YYY",
    TotalDL: "DD-MM-YYY",
    firstHrs: "Fully Redacted",
    firstDays: "Fully Redacted",
  },
];
export default function AudioPerformanceData({analyticData, spotifyAnalytics, appleAnalytics, megaphoneAnalytics}) {
  return (
    <div style={{marginTop: "30px"}}>
      <SubHeading>Total Analytics</SubHeading>
      <TopContainer style={{
        justifyContent: 'flex-start'
      }}>
        {analyticData.map((val) => {
          return <AnalyticCard isHorizontal val={val} />;
        })}
      </TopContainer>
      <SubHeading>Spotify Analytics</SubHeading>
      <TopContainer style={{
        justifyContent: 'flex-start'
      }}>
        {spotifyAnalytics.map((val) => {
          return <AnalyticCard isHorizontal val={val} />;
        })}
      </TopContainer>
      <SubHeading>Megaphone Analytics</SubHeading>
      <TopContainer style={{
        justifyContent: 'flex-start'
      }}>
        {megaphoneAnalytics.map((val) => {
          return <AnalyticCard isHorizontal val={val} />;
        })}
      </TopContainer>
      <SubHeading>Apple Analytics</SubHeading>
      <TopContainer style={{
        justifyContent: 'flex-start'
      }}>
        {appleAnalytics.map((val) => {
          return <AnalyticCard isHorizontal val={val} />;
        })}
      </TopContainer>
    </div>
  );
}
