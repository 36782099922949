import React, { useEffect, useState } from "react";
import { BsGrid1X2, BsSpeedometer2 } from "react-icons/bs";
import { TbChartArcs } from "react-icons/tb";
import { FaRegChartBar } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { toast } from "../../../helpers/toast";
import { useAuth } from "../../../contexts/authenticationContext";
import { Link, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  NavBarItem,
  Logo,
  Logout,
  SideBarContainer,
  HamburgerMenu,
  Overlay,
} from "./styles";
import axiosInstance from "../../../services/Axios";

const NAV_ITEMS = [
  {
    label: "Home",
    value: "home",
    img: <BsGrid1X2 />,
    link: "/home",
  },
  {
    label: "Dashboard",
    value: "dashboard",
    img: <BsSpeedometer2 />,
    link: "/dashboard",
  },
  {
    label: "Episodes",
    value: "episodes",
    img: <TbChartArcs />,
    link: "/episodes",
  },
  {
    label: "Charts",
    value: "charts",
    img: <FaRegChartBar />,
    link: "/charts",
  },
];

function SideNav(props) {
  const { logout, isLoggedIn } = useAuth();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [navItem, setNavItem] = useState(NAV_ITEMS[0].value);

  useEffect(() => {
    setNavItem(location.pathname.split("/")[1]);
  }, [location]);

  async function handleLogOut() {
    try {
      const res = await axiosInstance.post("/auth/logout/", "");
      if (res.status === 200) {
        logout();
        toast.success("Logged Out Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    }
  }

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return isLoggedIn ? (
    <>
      <HamburgerMenu>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleSidebarToggle}
        >
          <MenuIcon />
        </IconButton>
      </HamburgerMenu>
      <SideBarContainer isOpen={isSidebarOpen}>
        <nav>
          <Logo>
            <img src={require("../../../assests/Logo.png")} alt="Logo" />
          </Logo>
          {NAV_ITEMS.map(({ label, img, link, value }, index) => (
            <Link style={{ textDecoration: "none" }} to={link} key={index}>
              <NavBarItem
                selected={value === navItem}
                onClick={() => {
                  setNavItem(value);
                  setIsSidebarOpen(false);
                }}
              >
                {img}
                <div>{label}</div>
              </NavBarItem>
            </Link>
          ))}
        </nav>
        <Logout onClick={handleLogOut}>
          <MdLogout size={25} />
          LogOut
        </Logout>
      </SideBarContainer>
      <Overlay isOpen={isSidebarOpen} onClick={handleSidebarToggle} />
    </>
  ) : null;
}

export default SideNav;
