import React from "react";
import AnalyticCard from "../AnalyticCard";
import { TableContainer, TopContainer } from "./styles";
import BasicTable from "../BasicTable";
import { SubHeading } from "../AddPodcast/styles";
import { Skeleton } from "@mui/material";

const data = [
  {
    id: 1,
    title: "Average Views",
    value: "404000",
    // percentageChange: "0.8%",
    isIncreased: true,
  },
  {
    id: 2,
    title: "Average Subs",
    value: "300000",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
  {
    id: 3,
    title: "Average CTR",
    value: "404000",
    // percentageChange: "0.8%",
    isIncreased: true,
  },
  {
    id: 4,
    title: "Average APV",
    value: "300000",
    // percentageChange: "0.8%",
    isIncreased: false,
  },
];

const TableHeading = ["YT Ep", "Pod Ep", "Views", "Subs", "CTR", "APV"];
const TableData = [
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redacted",
  },
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redacted",
  },
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redacted",
  },
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redacted",
  },
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redacted",
  },
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redacted",
  },
  {
    YTepisode: "Victor Pacheco",
    PODepisode: "Yes",
    views: "Lorem",
    subs: "DD-MM-YYY",
    ctr: "DD-MM-YYY",
    apv: "Fully Redac",
  },
];
export default function VideoPerformanceData({analyticData, videoAnalytics, loading}) {
  console.log(loading, 'loading')
  return (
    <div style={{marginTop: "30px"}}>
      <SubHeading>{loading ? <Skeleton /> : 'Total Analytics'}</SubHeading>
      <TopContainer style={{
        justifyContent: 'flex-start'
      }}>
        {loading && <Skeleton />}
        {!loading && analyticData.map((val) => {
          return <AnalyticCard isHorizontal val={val} />;
        })}
      </TopContainer>
      <SubHeading>Youtube Analytics</SubHeading>
      <TopContainer style={{
        justifyContent: 'flex-start'
      }}>
      {loading && <Skeleton />}
      {
          !loading && videoAnalytics.map((val) => {
            return <AnalyticCard isHorizontal val={val} />;
          })
        }
      </TopContainer>
    </div>
  );
}
