import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";

const rowStyle = {
  fontFamily: "Inter",
  color: "#111827",
  fontWeight: "400",
};

const columnStyle = {
  fontFamily: "Inter",
  color: "#6B7280",
  fontWeight: "600",
};
export default function BasicTable({ TableHeading, TableData, totalCount, onPageChange, onRowsChangePerPage, page, rowsPerPage}) {
  const rows = Object.keys(TableData[0]);
  return (
    <TableContainer component={Paper} style={{ borderRadius: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {TableHeading.map((val) => {
              return <TableCell style={columnStyle}>{val}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {TableData.map((row) => (
            <TableRow
              key={row.YTepisode}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {rows.map((value) => {return <TableCell style={rowStyle}>{row[value]}</TableCell>; })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={totalCount || 10}
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsChangePerPage}
      />
    </TableContainer>
  );
}
