import styled from "styled-components";

export const EpisodesContainer = styled.div`
  background-color: #f8f6fc;
  width: 100%;
  min-height: 100vh;
  padding: 4rem;
`;

export const EpisodesHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #262930;
`;

export const EpisodesNav = styled.div`
  width: 90%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #dbdbdb;
  margin-top: 5rem;
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const EpisodevsNavItems = styled.div`
  border-bottom: ${({ selected }) => (selected && "4px solid #7367f0")}; 
  color: ${({ selected }) => (selected ? "#7367F0" : "#262930")};
  cursor: pointer;
`;

export const PlaceholderItem = styled.em`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #7367f0;
`;
