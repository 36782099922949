import styled from "styled-components";

export const TopContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const TableContainer = styled.div`
  margin-left: 5%;
  margin-top: 4rem;
  width: 100%;
`;
