import { Alert, Button, Divider, Input, Paper, Table, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../services/Axios";
import { getPodcast } from "../../services/podcast";
import {
  AddPodcastContainer,
  Heading,
  UploadGuideContainer,
  WorkSpaceBottomContainer,
  WorkSpaceContainer,
} from "../common/AddPodcast/styles";
import Navbar from "../Navbar";

const fieldMap = {
  "megaphone": ['PUBLISHED', 'DOWNLOADS', 'FIRST 24 HOURS', 'FIRST 7 DAYS', 'TO DATE', 'EPISODE'],
  "apple": ['Release Date', 'Duration', 'Unique Listeners', 'Unique Engaged Listeners', 'Average Consumption', 'Plays', 'Episode Title'],
  "spotify": ['name', 'starts', 'streams', 'listeners', 'releaseDate'],
  "youtube": [

  ]
}

const UploadCSV = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const { podcastId, platform } = useParams();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setError(null)
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    setError(null)
    formData.append("file", selectedFile);
    formData.append("id", podcastId);
    formData.append("platform", platform.toUpperCase());
    try {
        await axiosInstance.post('/analytics/upload-csv/', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        toast.success("Success");
        navigate(`/home/manage/${platform}/${podcastId}/episodes`);
    } catch (error) {
      setError(error.response.data.message)
        toast.error("Error")
    }
  }

  const handleGetPodcasts = async () => {
    setLoading(true);
    try {
      let response = await getPodcast(podcastId);
      console.log(response);
      setData(response.data);
    } catch (error) {
      toast.error(error.message.toString());
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    handleGetPodcasts();
  }, []);

  return (
    <WorkSpaceContainer>
      <Navbar />
      <WorkSpaceBottomContainer>
        <AddPodcastContainer>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography variant="h4">
                Upload CSV for {platform[0].toUpperCase() + platform.slice(1)}
              </Typography>
            </Box>
            <Divider sx={{ marginTop: "2rem" }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
            <UploadGuideContainer>
              <Heading>{data?.title}</Heading>
              <Box sx={{display: 'flex', margin: "2rem 0", width: '100%'}}>
              <input type="text" readOnly style={{height: "50px !important", width: "100%", outline: "none", border: "1px solid grey", color: 'grey'}} value={selectedFile ? selectedFile.name : "Select file"}/>
              <Button
                component="label"
                variant="outlined"
                sx={{ marginRight: "0rem", height: "50px", background: "#E4E8EF", width: "100px", border: "none", color: "black" }}>
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                  style={{padding: "5px"}}
                />
              </Button>
              </Box>
              {error && <Alert severity="error">{error}</Alert>}
              <Button disabled={!selectedFile} onClick={handleFileUpload} style={{ margin: "2rem 0", background: "#7367F0", color: "white" }}>Upload Now</Button>
            </UploadGuideContainer>
            <UploadGuideContainer>
              <Heading>Required fields for CSV</Heading>
              <Box sx={{display: 'flex', marginTop: "2rem", width: "100%", flexWrap: 'wrap'}}>
                  {fieldMap[platform].map(data => <Paper sx={{padding: "5px", margin: "5px"}}>
                    <Typography sx={{ display: 'flex'}} variant="body2">{data}</Typography>
                    </Paper>)}
            
              </Box>
            </UploadGuideContainer>
          </Box>
        </AddPodcastContainer>
      </WorkSpaceBottomContainer>
    </WorkSpaceContainer>
  );
};

export default UploadCSV;
