import React from "react";
import { useFormik } from "formik";
import {
  BackButton,
  BackButtonLink,
  FieldHeading,
  FormHeading,
  Input,
  LogInLink,
  ResetPasswordContainer,
  ResetPasswordFormContainer,
  SignInMessage,
  VerifyButton,
} from "./styles";
import { BackImg, SignInImage } from "../../assests";
import {
  ImageContainer,
  BannerImage,
  ErrorMessage,
} from "../SignInComponent/SignInComponentStyles";
import { emailValidation } from "../../helpers/validations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/Axios";

export default function ForgotPassword() {
  const history = useNavigate();
  const validate = (values) => {
    const errors = {};
    const emailError = emailValidation(values.email);
    if (emailError) {
      errors.email = emailError;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        const res = await axiosInstance.post("/auth/password_reset/", {
          email: formik.values.email,
        });
        if (res) {
          toast.success("Email Sent");
          history("/");
        }
      } catch (error) {
        toast.error(error.response.data.email[0]);
      }
    },
  });

  return (
    <>
      <ResetPasswordContainer>
        <ImageContainer>
          <BannerImage src={SignInImage} />
        </ImageContainer>
        <ResetPasswordFormContainer onSubmit={formik.handleSubmit}>
          <FormHeading>
            <BackButtonLink to={"/"}>
              <BackButton src={BackImg} />
            </BackButtonLink>
            Reset Password
          </FormHeading>

          <div>
            <FieldHeading>Email address</FieldHeading>
            <Input
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Enter your email address"
              onBlur={formik.handleBlur}
              type="text"
            />
            {formik.touched.email && formik.errors.email ? (
              <ErrorMessage>{formik.errors.email}</ErrorMessage>
            ) : null}
          </div>
          <VerifyButton type="submit">Submit</VerifyButton>
          <SignInMessage>
            Remember password? <LogInLink to={"/sign-in"}>Log in</LogInLink>
          </SignInMessage>
        </ResetPasswordFormContainer>
      </ResetPasswordContainer>
    </>
  );
}
