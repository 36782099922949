import styled from "styled-components";

export const DashboardContainer = styled.div`
  background-color: #f8f6fc;
  width: 100%;
  padding: 4rem;
  height: 100%;
`;

export const Heading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  color: #262930;
`;

export const DropDownContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
`;

export const PlaceholderItem = styled.em`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #ffffff;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 1px;
  margin-top: 2rem;
  width:1325px;
  position:relative;
  
`;

export const TableContainer = styled.div`
width:100%;
 
`;

export const TableTopContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 2rem;
  border-radius:20px 20px 0px 0px;
`;


export const TableContainerDashboard = styled.div`
  width: 100%;
 
  top: 775px;
  left: 250px;
  gap: 0px;
  border-radius: 30px;

`;


export const Dashboardtableheading = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #323335;
`;
export const DashboardtableheadingEpisode = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #323335;
  margin-left:30px;
`;

export const DashboardTableCell = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #858688;
  text-align:center;
`;
 