import React from "react";
import { useFormik } from "formik";
import {
  BackButton,
  BackButtonLink,
  FieldHeading,
  FormHeading,
  Input,
  LogInLink,
  ResetPasswordContainer,
  ResetPasswordFormContainer,
  SignInMessage,
  VerifyButton,
} from "./styles";
import { BackImg, SignInImage } from "../../assests";
import {
  ImageContainer,
  BannerImage,
  ErrorMessage,
} from "../SignInComponent/SignInComponentStyles";
import { toast } from "../../helpers/toast";
import axiosCall from "../../services/Axios";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/Axios";

export default function ChangePassword() {
  const history = useNavigate();
  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.confirmPassword) {
      errors.confirPassword = "Required";
    }
    return errors;
  };
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        toast.error("Password and Confirm Password does not match");
        return;
      }
      try {
        const res = await axiosInstance.post(
          `/auth/password_reset/confirm/?token=${token}`,
          {
            password: formik.values.password,
            token: token,
          }
        );

        if (res) {
          toast.success("Changed Successfuly");
          history("/");
        }
      } catch (error) {
        toast.error(error);
      }
    },
  });
  return (
    <>
      <ResetPasswordContainer>
        <ImageContainer>
          <BannerImage src={SignInImage} />
        </ImageContainer>
        <ResetPasswordFormContainer onSubmit={formik.handleSubmit}>
          <FormHeading>
            <BackButtonLink to={"/"}>
              <BackButton src={BackImg} />
            </BackButtonLink>
            Reset Password
          </FormHeading>

          <div>
            <FieldHeading>Create new password</FieldHeading>
            <Input
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="must be 8 characters"
              onBlur={formik.handleBlur}
              type="password"
            />
            {formik.touched.password && formik.errors.password ? (
              <ErrorMessage>{formik.errors.password}</ErrorMessage>
            ) : null}
          </div>
          <div>
            <FieldHeading>Confirm password</FieldHeading>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              placeholder="repeat password"
              onBlur={formik.handleBlur}
              type="password"
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <ErrorMessage>{formik.errors.confirmPassword}</ErrorMessage>
            ) : null}
          </div>
          <VerifyButton type="submit">Submit</VerifyButton>
          <SignInMessage>
            Remember password? <LogInLink to={"/sign-in"}>Log in</LogInLink>
          </SignInMessage>
        </ResetPasswordFormContainer>
      </ResetPasswordContainer>
    </>
  );
}
