import styled from "styled-components";

export const HomeNav = styled.div`
  width: 45%;
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 5rem;
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  // border-bottom: 1px solid #dbdbdb;
  border-bottom: ${(props) => (props.loading ? "none" : "1px solid #dbdbdb")};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 75%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  @media (max-width: 1075px) {
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
`;

export const HomeNavItems = styled.div`
  border-bottom: ${({ selected }) => selected && "4px solid #7367f0"};
  color: ${({ selected }) => (selected ? " " : "#262930")};
  cursor: pointer;
`;

export const WorkSpaceContainer = styled.main`
  background-color: #f8f6fc;
  padding: 1rem 2rem;
  width: 100%;
`;

export const WorkSpaceTopContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  color: #262930;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WorkSpaceTopRightSection = styled.div`
  display: flex;
`;

export const WorkSpaceBottomContainer = styled.div`
  display: flex;
  margin-top: 3rem;
`;

export const TopSubHeading = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  color: #81888e;
`;

export const AddPodcastContainer = styled.div`
  flex: 2;
  margin-top: 3rem;
  padding-right: 2rem;
  min-height: 100vh;
`;

export const Heading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #323335;
  margin-bottom: 0.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SubHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #81888e;
  margin-bottom: 2rem;
`;

export const UploadContainer = styled.div`
  width: 50rem;
  height: 21.6rem;
  border: 1px dashed #b0beca;
  padding: 1.5rem;
`;

export const UploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
  width: 15rem;
  height: 2.9rem;
  background: rgba(115, 103, 240, 0.42);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin-top: 0.5rem;
`;

export const UploadGuideContainer = styled.div`
  flex: 1;
  padding: 3rem 2rem;
  background: white;
  margin: 2rem;
  width: fit-content;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlatformUploadButton = styled.button`
  width: 16.31rem;
  height: 4.3rem;
  border: 1px solid #deddde;
  border-radius: 10px;
  font-family: "Inter";
  background: white;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #262930;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  margin: 1.5rem 0rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover {
    border: 1px solid grey;
  }
`;

export const PlatformButton = styled.div`
  padding: 0rem 1rem;
  height: 4.3rem;
  border: 1px solid #deddde;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #262930;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  min-width: 13rem;
`;

export const Heading2 = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: #323335;
  margin: 1rem 0rem;
`;



export const Button = styled.button`
  padding: 0rem 1rem;
  height: 37px;
  width: 158px;
  background: #ffffff;
  border: 1px solid #7367f0;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #81888e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
    align-self: flex-start;
    order: -1; 
  }
`;


