import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { BsMegaphone, BsSpotify, BsYoutube } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { SiApplepodcasts } from "react-icons/si";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../services/Axios";
import { getPodcast, getPodcasts } from "../../services/podcast";
import {
  AddPodcastContainer,
  Heading,
  PlatformUploadButton,
  SubHeading,
  UploadGuideContainer,
  WorkSpaceBottomContainer,
  WorkSpaceContainer,
} from "../common/AddPodcast/styles";
import Navbar from "../Navbar";

const Manage = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const { podcastId } = useParams();
  const navigate = useNavigate();
  const [youtubeData, setYoutubeData] = React.useState(null);
  const [youtubeLoading, setYoutubeLoading] = useState(false);

  const handleGetYoutubeData = async () => {
    try {
        const response = await axiosInstance.get(`/analytics/google/response?podcast_id=${podcastId}`);
        setYoutubeData(response.data);
    } catch (error) {
        console.log(error);
    }
  };

  React.useEffect(() => {
  }, [youtubeData])

  const handleGetAllPodcasts = async () => {
    setLoading(true);
    try {
      let response = await getPodcast(podcastId);
      console.log(response);
      setData(response.data);
    } catch (error) {
      toast.error(error.message.toString());
      console.log(error);
    }
    setLoading(false);
  };

  const uploadYoutubeData = async () => {
    setYoutubeLoading(true);
    try {
      const response = await axiosInstance.post(`/analytics/youtube/`, {
        podcast_id: Number(podcastId),
      });
      toast.success(response.data.success || "Success")
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.msg)
    }
    setYoutubeLoading(false);
  }

  React.useEffect(() => {
        handleGetAllPodcasts()
        handleGetYoutubeData()
  }, []);

  return (
    <WorkSpaceContainer>
      <Navbar />
      <WorkSpaceBottomContainer>
        <AddPodcastContainer>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography variant="h4">{data?.title}</Typography>
            </Box>
            <Divider sx={{ marginTop: "2rem" }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
            <UploadGuideContainer>
              <Heading>Upload Guide </Heading>
              <SubHeading>Podcast CSV upload guide</SubHeading>
              <PlatformUploadButton onClick={e => navigate(`/home/manage/megaphone/${podcastId}`)}>
                <BsMegaphone color="#fba257" /> Megaphone
              </PlatformUploadButton>
              <PlatformUploadButton onClick={e => navigate(`/home/manage/apple/${podcastId}`)}>
                <SiApplepodcasts color="#cc00d8" /> Apple Podcasts
              </PlatformUploadButton>
              <PlatformUploadButton onClick={e => navigate(`/home/manage/spotify/${podcastId}`)}>
                <BsSpotify color="#1ED760" /> Spotify
              </PlatformUploadButton>
            </UploadGuideContainer>
            <UploadGuideContainer>
                <Heading>
                    Google Account
                </Heading>
                {youtubeData && youtubeData.channel_name && youtubeData.email ? 
                (
                    <React.Fragment>
                        <Typography variant="h6">{youtubeData.channel_name}</Typography>
                    <SubHeading>{youtubeData.email}</SubHeading>
                    </React.Fragment>
                ) : 
                <SubHeading>No account connected</SubHeading> }
                <PlatformUploadButton disabled={loading} onClick={e => window.open(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000'}/analytics/google/auth?podcast_id=${podcastId}`, "_self")}>
                <FcGoogle size={25}/> Login with google
                </PlatformUploadButton>
                {!loading && youtubeData && youtubeData.channel_name && youtubeData.email && 
                <PlatformUploadButton disabled={loading || youtubeLoading} onClick={uploadYoutubeData}>
                  <FaYoutube size={25} color="red"/>{youtubeLoading ? "Syncing" : 'Sync with Youtube'}
                </PlatformUploadButton>}
            </UploadGuideContainer>
          </Box>
        </AddPodcastContainer>
      </WorkSpaceBottomContainer>
    </WorkSpaceContainer>
  );
};

export default Manage;
