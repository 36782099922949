import styled from "styled-components";

// export const SideBarContainer = styled.div`
//   display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
//   flex-direction: column;
//   justify-content: space-between;
//   max-height: 100vh;
//   position: sticky;
//   top: 0;
//   max-width: 20%;
//   width: 20%;
//   left: 0;
//   background-color: white; /* Added background color for visibility */

//   @media (min-width: 992px) {
//     display: flex; /* Always show the sidebar on larger screens */
//   }
// `;

export const SideBarContainer = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;  /* Changed to fixed for better handling on smaller screens */
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  width: 80%;  /* Default width for small screens */
  max-width: 300px;  /* Maximum width for small screens */

  @media (min-width: 576px) {
    width: 250px;  /* Width for slightly larger screens */
  }

  @media (min-width: 768px) {
    width: 300px;  /* Width for medium screens */
  }

  @media (min-width: 992px) {
    width: 20%;  /* Width for large screens */
    max-width: 20%;
    display: flex;  /* Always show the sidebar on larger screens */
    position: sticky;
  }
`;

export const NavBarItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1rem;
  margin: 0.5rem 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: ${({ selected }) => (selected ? "#ffffff" : "#858688")};
  background: ${({ selected }) => selected && "#7367f0"};
  border-radius: 6px;
`;

export const Logo = styled.div`
  margin: 2rem;
  left:10rem;
`;

export const Logout = styled.div`
  margin: 2.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #858688;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const HamburgerMenu = styled.div`
  position: fixed;
  top: 27px;
  gap:1;
  left: 10px;
  z-index: 1100; /* Higher than the sidebar */
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 900; /* Lower than the sidebar */

  @media (min-width: 992px) {
    display: none; /* Hide overlay for medium and large screens */
  }
`;
