import React, { useEffect, useState } from "react";
import SideNav from "../common/SideNav";
import {
  EpisodesContainer,
  EpisodesHeading,
  EpisodesNav,
  EpisodevsNavItems,
  PlaceholderItem,
} from "./styles";
import VideoPerformanceData from "../common/VideoPerformanceData";
import { MenuItem, Select, TableContainer } from "@mui/material";
import AudioPerformanceData from "../common/AudioPerformanceData";
import axiosInstance from "../../services/Axios";
import { toast } from "react-toastify";
import { getEpisodesByPodcastId, getPodcasts } from "../../services/podcast";
import BasicTable from "../common/BasicTable";
import { Heading } from "../common/AnalyticCard/styles";
import { Button } from "../common/AddPodcast/styles";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function Episodes() {
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [episodesData, setEpisodesData] = useState([]);
  const [loadingEpisodes, setLoadingEpisodes] = useState(false);
  const [loadingPodcasts, setLoadingPodcasts] = useState(false);
  const [podcastData, setPodcastData] = useState([]);
  const [selected, setSelected] = useState("VPD");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [analyticData, setAnalyticData] = useState([
    {
      id: 1,
      title: "Episodes Released",
      label: "total_episodes",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Views",
      label: "total_views",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
    {
      id: 3,
      title: "Downloads",
      label: "total_downloads",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 4,
      title: "Subscribers",
      label: "total_subscribers",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
  ]);
  const [videoAnalytics, setVideoAnalytics] = useState([
    {
      id: 1,
      title: "Youtube Views",
      label: "views",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Youtube Subscribers",
      label: "subscribers",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
    {
      id: 3,
      title: "CTR",
      label: "click_through_rate",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 4,
      title: "APV",
      label: "average_percentage_viewed",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
  ]);
  const [spotifyAnalytics, setSpotifyAnalytics] = useState([
    {
      id: 1,
      title: "Starts",
      label: "starts",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Streams",
      label: "streams",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
  ]);

  const [megaphoneAnalytics, setMegaphoneAnalytics] = useState([
    {
      id: 1,
      title: "First 24 hours",
      label: "first_24_hours",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "First 7 days",
      label: "first_7_days",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
  ]);

  const [appleAnalytics, setAppleAnalytics] = useState([
    {
      id: 1,
      title: "Unique Listeners",
      label: "unique_listeners",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Unique Engaged Listeners",
      label: "unique_engaged_listeners",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
    {
      id: 1,
      title: "Plays",
      label: "plays",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: true,
    },
    {
      id: 2,
      title: "Duration",
      label: "duration",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
    {
      id: 2,
      title: "Average Consumption",
      label: "average_consumption",
      value: 0,
      // percentageChange: "0.8%",
      isIncreased: false,
    },
  ]);

  const getPodcastsData = async () => {
    setLoadingPodcasts(true);
    try {
      let response = await getPodcasts();
      setPodcastData(response.data);
    } catch (error) {
      toast.error(error.message.toString());
    }
    setLoadingPodcasts(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getEpisodes = async () => {
    try {
      const response = await getEpisodesByPodcastId(selectedPodcast, null, null, rowsPerPage, rowsPerPage*page);
      if (response.data.msg) {
        setEpisodesData([]);
      } else {
        setEpisodesData(response.data);
        setAnalyticData(
          analyticData.map((analytic) => {
          const key = analytic.label;
            const value = response.data[key];

            return {
              ...analytic,
              value: value ?? 0,
            };
          })
        );
      }
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  const getYoutubeAnalytics = async () => {
    try {
      const response = await axiosInstance.get(
        `/analytics/platform/YOUTUBE/podcast/${selectedPodcast}`
      );
      const videoSerial = {
        views: 0,
        subscribers: 0,
        click_through_rate: 0,
        average_percentage_viewed: 0,
      };

      const {total_views, total_subscribers, total_ctr, total_avp } = response.data;
      videoSerial.views = total_views;
      videoSerial.subscribers = total_subscribers;
      videoSerial.average_percentage_viewed = total_avp;
      videoSerial.click_through_rate = total_ctr;

      setVideoAnalytics(
        videoAnalytics.map((analytic) => {
          const key = analytic.label;
          const value = videoSerial[key];

          return {
            ...analytic,
            value: value ?? 0,
          };
        })
      );
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  const getSpotfiyAnalytics = async () => {
    try {
      const response = await axiosInstance.get(
        `/analytics/platform/SPOTIFY/podcast/${selectedPodcast}`
      );

      const spotifySerial = {
        starts: 0,
        streams: 0,
      };

      const {total_starts, total_streams} = response.data;

        spotifySerial.starts = total_starts;
        spotifySerial.streams = total_streams;

      setSpotifyAnalytics(
        spotifyAnalytics.map((analytic) => {
          const key = analytic.label;
          const value = spotifySerial[key];

          return {
            ...analytic,
            value: value ?? 0,
          };
        })
      );
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  const getMegaphoneAnalytics = async () => {
    try {
      const response = await axiosInstance.get(
        `/analytics/platform/MEGAPHONE/podcast/${selectedPodcast}`
      );

      const megaphoneSerial = {
        downloads: 0,
        first_24_hours: 0,
        first_7_days: 0,
      };

      const {total_downloads, total_first_24_hours, total_first_7_days} = response.data;

      megaphoneSerial.downloads = total_downloads;
      megaphoneSerial.first_24_hours = total_first_24_hours;
      megaphoneSerial.first_7_days = total_first_7_days;

      setMegaphoneAnalytics(
        megaphoneAnalytics.map((analytic) => {
          const key = analytic.label;
          const value = megaphoneSerial[key];

          return {
            ...analytic,
            value: value ?? 0,
          };
        })
      );
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  const getAppleAnalytics = async () => {
    try {
      const response = await axiosInstance.get(
        `/analytics/platform/APPLE/podcast/${selectedPodcast}`
      );

      const appleSerial = {
        duration: 0,
        unique_listeners: 0,
        plays: 0,
        unique_engaged_listeners: 0,
        average_consumption: 0,
      };

      const { total_unique_listeners,
      total_unique_engaged_listeners,
      total_plays,
      total_duration,
      total_average_consumption
    } = response.data;

        appleSerial.duration = total_duration;
        appleSerial.unique_listeners = total_unique_listeners;
        appleSerial.unique_engaged_listeners = total_unique_engaged_listeners;
        appleSerial.plays = total_plays;
        appleSerial.average_consumption = total_average_consumption;

      setAppleAnalytics(
        appleAnalytics.map((analytic) => {
          const key = analytic.label;
          const value = appleSerial[key];

          return {
            ...analytic,
            value: value ?? 0,
          };
        })
      );
    } catch (error) {
      toast.error(error.message.toString());
    }
  };

  const getEpisodeData = async () => {
    setLoadingEpisodes(true)
    try {
      await getEpisodes();
      await getYoutubeAnalytics();
      await getSpotfiyAnalytics();
      await getMegaphoneAnalytics();
      await getAppleAnalytics();
    } catch (error) {
      console.log(error)
    }
    setLoadingEpisodes(false)
  }

  useEffect(() => {
    getPodcastsData();
  }, []);

  useEffect(() => {
    console.log(loadingEpisodes, "loading episodes")
  }, [loadingEpisodes])

  useEffect(() => {
    if (selectedPodcast) {
      getEpisodeData()
    }
  }, [selectedPodcast, page, rowsPerPage]);

  return (
    <EpisodesContainer>
      <EpisodesHeading>Episodes</EpisodesHeading>
      
       {loadingPodcasts ? <Skeleton width={170} height={50} borderRadius={10} /> : 
      <Select 
        value={selectedPodcast ? selectedPodcast : ""}
        onChange={(e) => {
         setSelectedPodcast(e.target.value);
        }}
         placeholder={"Select Podcast"}
         inputProps={{ "aria-label": "Without label" }}
        style={{
          width: 170,
           borderRadius: 10,
           background: "#7367F0",
          color: "#ffffff",
           marginTop: "20px",
         }}
         sx={{
          "& .css-hdw1oc": { width: 0 },
         }}>
        {podcastData &&
          podcastData.map((d) => (
            <MenuItem value={d.id} key={d.id}>
               {d.title.length > 20 ? d.title.slice(0, 20) + "..." : d.title}
            </MenuItem>
           ))}
       </Select>  }
    
    
      <EpisodesNav>
        <div style={{ display: "flex", gap: "5rem", width: '100%t' }}>
          <>
          {loadingPodcasts ? <Skeleton height={50} width={200} style={{margin: "10px"}}/> :
          <EpisodevsNavItems
            onClick={() => setSelected("VPD")}
            selected={selected === "VPD"}>
            Video Performance Data
          </EpisodevsNavItems> 
          }
          {loadingPodcasts ? <Skeleton height={50} width={200} style={{margin: "10px"}}/> :
          <EpisodevsNavItems
            onClick={() => setSelected("APD")}
            selected={selected === "APD"}>
            Audio Performance Data
          </EpisodevsNavItems>
          }
          </>
        </div>
      </EpisodesNav>
      {selectedPodcast !== null ? (
        <React.Fragment>
          {selected === "VPD" ? (
            loadingEpisodes ? 
            <Skeleton width={"100%"} height={200}/>
            :
            <VideoPerformanceData
              analyticData={analyticData}
              videoAnalytics={videoAnalytics}
              loading={loadingEpisodes}
            />
          ) : (
            loadingEpisodes ? 
            <Skeleton width={"100%"} height={200} style={{margin: "20px"}}/>
            :
            <AudioPerformanceData
              analyticData={analyticData}
              spotifyAnalytics={spotifyAnalytics}
              megaphoneAnalytics={megaphoneAnalytics}
              appleAnalytics={appleAnalytics}
              loading={loadingEpisodes}
            />
          )}
          {loadingEpisodes ? 
          <Skeleton width={"100%"} height={300}/>
          :
          <TableContainer>
            {episodesData.episodes && episodesData.episodes.length > 0 && (
              <BasicTable
                totalCount={episodesData.total_episodes}
                onPageChange={handlePageChange}
                onRowsChangePerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                TableHeading={[
                  "Title",
                  "Release Date",
                  "Downloads",
                  "Apple Plays",
                  "Spotify Streams",
                  "Youtube Views",
                  "Actions",
                ]}
                TableData={episodesData.episodes.map((d) => ({
                  episode_title: (
                    <a href={`/episodes/${d.id}`}>{
                      d.title.length > 40
                      ? d.title.slice(0, 40) + "..."
                      : d.title
                    }</a>
                  ),
                  publish_date: d.published_date,
                  downloads: d.downloads,
                  applePlays: d.apple_plays,
                  spotifyStreams: d.spotify_streams,
                  youtubeViews: d.yt_views,
                  actions: (
                    <Button onClick={(e) => navigate(`/episodes/${d.id}`)}>
                      See info
                    </Button>
                  ),
                }))}
              />
            )}
          </TableContainer>}
        </React.Fragment>
      ) : (!loadingPodcasts && 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}>
          <Heading>Please select a podcast</Heading>
        </div>
      )}
    </EpisodesContainer>
  );
}
